/**
 * Imports
 */
import * as React from 'react';

import { SupportTransactionList } from '../components';
import { useSelector, useDispatch } from 'react-redux';
import { getMerchants } from 'features/merchant/merchantAPI';
import { populateMerchants } from 'features/merchant/redux';
import { merchantsSelector } from 'features/merchant/redux/selectors';
import { BaseLayout } from 'features/navigation';
/**
 * Types
 */
type TrxnPageProps = {};

/**
 * LoginPage component
 */
const SupportTransactionPage: React.FC<TrxnPageProps> = () => {
  const merchants = useSelector(merchantsSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchMerchants = async () => {
    if (merchants.length === 0) {
      const data = await getMerchants();
      dispatch(populateMerchants(data));
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchMerchants();
    // eslint-disable-next-line
  }, []);

  if (loading) return null;
  return (
    <BaseLayout>
      <SupportTransactionList merchants={merchants} />
    </BaseLayout>
  );
};

export { SupportTransactionPage };

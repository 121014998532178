/**
 * Imports
 */
import { FIREBASE_CONFIG } from 'config';
import firebase from 'firebase';
import { Firebase } from 'firebaseAPI';
import * as React from 'react';
import moment from 'moment'
/**
 * Types
 */
export type TransfersHandlers = {
  onGetTransfers: () => Promise<void>;
};
export type TransfersType = {
  handlers: TransfersHandlers;
  isLoading: boolean;
  bankFolders: Firebase.BankFolder[];
};

/**
 * useTransfers
 */
const useTransfers = (): TransfersType => {
  const [bankFolders, setBankFolders] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    handlers.onGetTransfers();
    //eslint-disable-next-line
  }, []);

  const convertFiles = async (
    folder: firebase.storage.Reference,
  ): Promise<Firebase.BankFile[]> => {
    const files = await folder.listAll();
    const formatted = await Promise.all(
      files.items.map(async (file) => {
        return {
          fileName: file.name,
          downloadUrl: await file.getDownloadURL(),
        };
      }),
    );
    return formatted;
  };

  const _convertFolders = async (
    list: firebase.storage.Reference[],
  ): Promise<Firebase.BankFolder[]> => {
    const converted = await Promise.all(
      list.map(async (folder) => {
        const folderName = folder.name;
        const files = await convertFiles(folder);
        return {
          folderName,
          date: new Date(folderName),
          files,
        };
      }),
    );

    return converted;
  };

  const handlers: TransfersHandlers = {
    /**
     * onGetTransfers
     */
    onGetTransfers: async () => {
      setIsLoading(true);
      const storage = firebase
        .app()
        .storage(FIREBASE_CONFIG.bucketBankFiles)
        .ref();

        
      const day1 = moment().prevBusinessDay().businessAdd(1).format('YYYY-MM-DD');
      const day2 = moment().prevBusinessDay().format('YYYY-MM-DD');
      const day3 = moment().prevBusinessDay().businessAdd(-1).format('YYYY-MM-DD');
      const day4 = moment().prevBusinessDay().businessAdd(-2).format('YYYY-MM-DD');
      const day5 = moment().prevBusinessDay().businessAdd(-3).format('YYYY-MM-DD');
      const day1Folder = storage.child(day1);
      const day2Folder = storage.child(day2);
      const day3Folder = storage.child(day3);
      const day4Folder = storage.child(day4);
      const day5Folder = storage.child(day5);
      const folders = [day1Folder, day2Folder, day3Folder, day4Folder, day5Folder];
      const formatted = await _convertFolders(folders);
      if (folders.length > 0) {
        setBankFolders(
          formatted.sort((a, b) => b.date.valueOf() - a.date.valueOf()),
        );
      }
      setIsLoading(false);
    },
  };

  return { handlers, isLoading, bankFolders };
};

/**
 * Exports
 */
export { useTransfers };

/**
 * Imports
 */
import * as React from 'react';
import baseStyled, {
  ThemedStyledInterface,
  ThemeProvider as BaseThemeProvider,
  createGlobalStyle,
} from 'styled-components';

import { DashboardColors } from './colors';
import { Transitions } from './transitions';

/**
 * GloblaStyle
 */
const GlobalStyle = createGlobalStyle`
  body,
  html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
               Roboto, Oxygen-Sans, Ubuntu, Cantarell,
               "Helvetica Neue", sans-serif;;
    font-style: normal;
    font-weight: normal;
    overflow-x: hidden;
    overflow-y: auto;
  }
  span {
    display: block;
  }
`;

/**
 * Theme
 */
const theme = {
  Dashboard: {
    Colors: DashboardColors,
  },
};

/**
 * Types
 */
export type DashboardTheme = typeof theme;

/**
 * `styled` object for themed styled-components
 */
const styled: ThemedStyledInterface<DashboardTheme> = baseStyled;
const ThemeProvider: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>
  </>
);

/**
 * Exports
 */
export { theme, styled, ThemeProvider, Transitions };

/**
 * Imports
 */
import { MerchantPage } from 'features/merchant';
import { HomePage } from 'features/overview';
import { SettlementDetailsPage, SettlementPage } from 'features/settlement';
import { TerminalPage } from 'features/terminal';
import { SupportTransactionPage, TransactionPage } from 'features/transaction';
import { TransferPage } from 'features/transfers';
import { LoginPage } from 'features/user/pages';
import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { styled } from 'theme';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

/**
 * Styled components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) =>
    props.theme.Dashboard.Colors.Background.Secondary};
`;

/**
 * CONST
 */
export type RoutesType =
  | 'home'
  | 'login'
  | 'merchants'
  | 'merchantsTransactions'
  | 'merchantsTerminals'
  | 'settlements'
  | 'settlementDetails'
  | 'transactions'
  | 'transfers';

export type RouteLookup = {
  [k in RoutesType]: RouteType;
};

export type RouteType = {
  pageName: string | React.ReactNode;
  component: React.FC;
  path: any;
  route: string;
  isPrivate: boolean;
};

/**
 * ROUTES
 */
export const ROUTES: RouteLookup = {
  home: {
    pageName: 'Home',
    component: HomePage,
    path: '/',
    route: '/',
    isPrivate: true,
  },
  login: {
    pageName: 'Login',
    component: LoginPage,
    path: '/login',
    route: '/login',
    isPrivate: false,
  },
  merchants: {
    pageName: 'Merchants',
    component: MerchantPage,
    path: (id: string) => `/merchants/${id}`,
    route: '/merchants/:id',
    isPrivate: true,
  },
  merchantsTransactions: {
    pageName: 'Merchants Transactions',
    component: TransactionPage,
    path: (id: string) => `/merchants/${id}/transactions`,
    route: '/merchants/:id/transactions',
    isPrivate: true,
  },
  merchantsTerminals: {
    pageName: 'Merchants Terminals',
    component: TerminalPage,
    path: (mId: string, tId: string) => `/merchants/${mId}/terminals/${tId}`,
    route: '/merchants/:mId/terminals/:tId',
    isPrivate: true,
  },
  settlements: {
    pageName: 'Settlements',
    component: SettlementPage,
    path: '/settlements',
    route: '/settlements',
    isPrivate: true,
  },
  settlementDetails: {
    pageName: 'Settlements Details',
    component: SettlementDetailsPage,
    path: (settlementId: string) => `/settlements/${settlementId}`,
    route: '/settlements/:settlementId',
    isPrivate: true,
  },
  transactions: {
    pageName: 'Transactions',
    component: SupportTransactionPage,
    path: '/all_transactions',
    route: '/all_transactions',
    isPrivate: true,
  },
  transfers: {
    pageName: 'Transfers',
    component: TransferPage,
    path: '/transfers',
    route: '/transfers',
    isPrivate: true,
  },
};

/**
 * Types
 */
export interface RootRouterProps {}

/**
 * RootRouter component
 */
const RootRouter: React.FC<RootRouterProps> = () => {
  return (
    <BrowserRouter>
      <Root>
        <Switch>
          {Object.values(ROUTES).map((route) => {
            const RouteContainer = route.isPrivate ? PrivateRoute : PublicRoute;

            return (
              <RouteContainer
                exact
                key={route.route}
                path={route.route}
                component={route.component}
              />
            );
          })}
          <Route path="*" component={() => <h1>404</h1>} />
        </Switch>
      </Root>
    </BrowserRouter>
  );
};

/**
 * Exports
 */
export { RootRouter };

/**
 * Imports
 */
import * as React from 'react';

import { TerminalDetails } from '../components';

import { useParams } from 'react-router-dom';
import { BaseLayout } from 'features/navigation';
/**
 * Types
 */
type TerminalPageProps = {};
type ParamTypes = {
  tId: string;
  mId: string;
};

/**
 * LoginPage component
 */
const TerminalPage: React.FC<TerminalPageProps> = () => {
  console.log('terminal page loaded');

  const { tId, mId } = useParams<ParamTypes>();

  return (
    <BaseLayout>
      <TerminalDetails merchantId={mId} terminalId={tId} />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { TerminalPage };

/**
 * Imports
 */
import { Merchant } from 'types';
import { Table, Button } from 'antd';
import { TerminalModal } from 'features/terminal';
import moment from 'moment';
import * as React from 'react';

import styled from 'styled-components';

import { Terminal } from 'types';
/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
/**
 * Types
 */
type TerminalListProps = {
  fetchTerminals: () => Promise<void>;
  merchant: Merchant;
  terminals: Terminal[];
};

/**
 * MerchantDetails component
 */
const TerminalList: React.FC<TerminalListProps> = ({
  terminals,
  merchant,
  fetchTerminals,
}) => {
  const [terminal, setTerminal] = React.useState<Terminal | null>(null);
  const onClickDetails = (_terminal: Terminal) => {
    setTerminal(_terminal);
  };

  const columns = [
    {
      title: 'Terminal id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: 'Address',
      dataIndex: 'addressLine1',
      key: 'addressLine1',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (a: boolean) => (a ? 'Yes' : 'No'),
    },
    {
      title: 'Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (d: string) => moment(d).format('MMMM Do YYYY'),
    },
    {
      title: '',
      key: 'action',
      render: (_text: any, record: Terminal) => (
        <Button
          onClick={() => onClickDetails(record)}
          type="primary"
          size="middle"
        >
          Details
        </Button>
      ),
    },
  ];
  return (
    <Root>
      <Table columns={columns} dataSource={terminals} size={'small'} />
      {terminal && (
        <TerminalModal
          modalType="update"
          fetchTerminals={fetchTerminals}
          merchantId={merchant.merchantId}
          show={terminal !== null}
          closeModal={() => setTerminal(null)}
          terminal={terminal}
        />
      )}
    </Root>
  );
};

/**
 * Exports
 */
export { TerminalList };

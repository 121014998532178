/**
 * All colors and their variations
 */
const ColorShades = {
  ///////////////////////////////////////////////////////////////////////////////////
  // Vibrant Colors
  ///////////////////////////////////////////////////////////////////////////////////
  /**
   *Brand
   */
  Brand500: '#FF7222',
  /**
   * Black
   */
  Black000: '#000000',
  /**
   * White
   */
  White000: '#FFFFFF',
  /**
   * Grey
   */
  Grey1000: '#0C0C0E',
  Grey600: '#030320', // (e.g. Body Primary & Heading Primary)
  // Grey500: '#6E6E6E', // Icons Idle
  // Grey400: '#919191', // Text Secondary (Paragraphs)
  // Grey300: '#ACACAC', // Text Disabled
  // Grey200: '#C8C8C8', // Border Secondary
  Grey100: '#F0EFEE', // Border Primary
  // Grey50: '#F1F1F1', // Surface Tertiary
  Gray25: '#F9F9F9', // Surface Secondary
  /**
   * Blue
   */
  Blue500: '#0E2FA5',
  /**
   * Red
   */
  Red500: '#E7153B',
  /**
   * Yellow
   */
  Yellow500: '#FFD357',
  /**
   * Green
   */
  Green500: '#69D6A0',
  /**
   * Orange
   */
  Orange500: '#FF7222',
};

/**
 * Main variation of colors
 */
const BaseColors = {
  Brand: ColorShades.Brand500,
  Blue: ColorShades.Blue500,
  Green: ColorShades.Green500,
  Orange: ColorShades.Orange500,
  Yellow: ColorShades.Yellow500,
  Black: ColorShades.Black000,
  White: ColorShades.White000,
};

/**
 * Backgrounds
 */
const BackgroundColors = {
  // Main
  Primary: ColorShades.White000,
  Secondary: ColorShades.Gray25,
  // Tertiary: ColorShades.Grey50,
  // Misc
  // Disabled: ColorShades.Grey50,
  Dark: ColorShades.Black000,
  // Light: ColorShades.Gray25,
  // DarkGrey: ColorShades.Grey200,
};

/**
 * Border & Strokes
 */
const BorderColors = {
  BorderPrimary: ColorShades.Grey100, //  Borders on Secondary & Tertiary Surface
  // BorderSecondary: ColorShades.Grey200, // Borders on Primary Surfaces
  // ImageStroke: ColorShades.BlackOpacity01, // Image border
  // StrokePrimary: ColorShades.BlackOpacity01, // Borders with opacity
};

/**
 * Button colors
 */
const ButtonColors = {
  // Primary: ColorShades.Pink600,
  // PrimaryHover: ColorShades.Pink700,
  // PrimaryActive: ColorShades.Pink500,
};

/**
 * Elevation (drop shadow)
 */
const ElevationColors = {
  // ElevationPrimary: ColorShades.BlackOpacity008,
};

/**
 * Feedback colors (ex. system, error, and success messages,)
 */
const FeedbackColors = {
  // Danger: ColorShades.Orange600,
  // Info: ColorShades.Blue600,
  // Success: ColorShades.Green600,
  // Warning: ColorShades.Yellow600,
};

/**
 * Translucent (colors with opacity)
 */
const TranslucentColors = {
  // Translucent20: 'rgba(250, 250, 250, 0.2)',
  // Translucent40: 'rgba(250, 250, 250, 0.4)',
  // Translucent60: 'rgba(38, 38, 38, 0.2)',
  // Translucent80: 'rgba(38, 38, 38, 0.5)',
};

/**
 * Typography
 */
const TextColors = {
  // -------- Brand -----------
  Brand: ColorShades.Brand500,
  // Primary: ColorShades.Grey600,
  // Secondary: ColorShades.Grey400,
  // Dark: ColorShades.Grey600,
  // Light: ColorShades.Grey400,
  // Feedback
  // Danger: ColorShades.Orange600,
  // Info: ColorShades.Blue600,
  // Success: ColorShades.Green600,
  // Warning: ColorShades.Yellow600,
  // Text elements
  Heading: ColorShades.Grey600,
  // Input: ColorShades.Grey400,
  // Label: ColorShades.Grey400,
  Paragraph: ColorShades.Grey600,
  // Placeholder: ColorShades.Grey400,
  // UI & States
  // Disabled: ColorShades.Grey300,
  // Icon: ColorShades.Grey500,
  // Link: ColorShades.Blue600,
  // LinkHover: ColorShades.Blue700,
  // Basic
  Black: ColorShades.Black000,
  White: ColorShades.White000,
};

/**
 * Dashboard Theme Colors
 * One Const to rule them all,
 * Main export of theme colors to be used
 */
const DashboardColors = {
  // Color variants
  Base: BaseColors,
  Shades: ColorShades,
  // In-use
  Background: BackgroundColors,
  Border: BorderColors,
  Button: ButtonColors,
  Elevation: ElevationColors,
  Feedback: FeedbackColors,
  Opacity: TranslucentColors,
  Text: TextColors,
};

export { DashboardColors };

/**
 * Imports
 */
import * as React from 'react';
import { Divider, Table } from 'antd';
import styled from 'styled-components';
import { Posting, Settlement } from 'types';
import moment from 'moment';
import { Merchant } from 'types';

import { getMerchantById } from '../../merchant/merchantAPI';
/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
`;
const LeftHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const RightHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const SmallDivider = styled(Divider)`
  margin: 0;
`;
const Open = styled.span`
  color: #52c41a;
  font-weight: bold;
`;
const PaymentRow = styled.div`
  display: flex;
  flex: 1;
  margin: 0;
  justify-content: space-between;
`;

const parsePrice = (amount: number, currency: string) => {
  return new Intl.NumberFormat('gb-UK', {
    style: 'currency',
    currency: currency,
  }).format(amount / 100);
};
const parseFee = (amount: number, currency: string) => {
  return new Intl.NumberFormat('gb-UK', {
    style: 'currency',
    currency: currency,
  }).format(-amount / 100);
};
const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'No Transactions',
    dataIndex: 'noTransactions',
    key: 'noTransactions',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Gross Amount',
    render: (_text: any, record: Posting) =>
      parsePrice(record.grossAmount, record.currencyCode),
    key: 'grossAmount',
  },
  {
    title: 'Net Amount',
    render: (_text: any, record: Posting) =>
      parsePrice(record.netAmount, record.currencyCode),
    key: 'netAmount',
  },
  {
    title: 'Net Fee Amount',
    render: (_text: any, record: Posting) =>
      parsePrice(record.settlementFeeAmount, record.currencyCode),
    key: 'feeAmount',
  },
  {
    title: 'Fixed Fee Amount',
    render: (_text: any, record: Posting) =>
      parsePrice(record.fixedFeeAmount, 'EUR'),
    key: 'fixedFeeAmount',
  },
  {
    title: 'Percentage Fee Amount',
    render: (_text: any, record: Posting) =>
      parsePrice(record.percentageFeeAmount, record.currencyCode),
    key: 'percentageFeeAmount',
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
  },
  {
    title: 'Product Type',
    render: (_text: any, record: Posting) => {
      const mapper: any = {
        Private: 'Consumer',
        Commercial: 'Commercial',
      };
      return mapper[record.productType];
    },
    key: 'productType',
  },
  {
    title: 'Scheme',
    dataIndex: 'scheme',
    key: 'scheme',
  },
  {
    title: 'Terminal id',
    dataIndex: 'terminalId',
    key: 'terminalId',
  },
  {
    title: 'Descriptor',
    dataIndex: 'descriptor',
    key: 'descriptor',
  },
];
/**
 * Types
 */
type SettlementDetailsProps = {
  loading: boolean;
  postings: Posting[];
  settlement: Settlement;
};

/**
 * LoginPage component
 */
const SettlementDetails: React.FC<SettlementDetailsProps> = ({
  settlement,
  postings,
  loading,
}) => {
  const salesFeeAmount = postings.reduce((a, p) => {
    if (p.type === 'TransactionBatch' || p.type === 'Transaction') {
      return a + p.settlementFeeAmount;
    }
    return a;
  }, 0);

  const otherFeeAmount = postings.reduce((a, p) => {
    if (['Fee', 'Reversal'].includes(p.type)) {
      return a + p.settlementFeeAmount;
    }
    return a;
  }, 0);

  const [merchant, setMerchant] = React.useState<Merchant | null>(null);

  const fetchMerchant = async () => {
    const merchant = await getMerchantById(settlement.merchantId);
    setMerchant(merchant);
  };

  React.useEffect(() => {
    fetchMerchant();
    // eslint-disable-next-line
  }, []);

  const from = moment.unix(settlement.startedAt).utc().local();

  const to = settlement.postedAt
    ? moment.unix(settlement.postedAt).utc().local().subtract(1, 'days')
    : null;

  const toDate = to && from > to ? from : to;

  const postedAt = settlement.postedAt
    ? moment.unix(settlement.postedAt).utc().local()
    : null;

  const payoutAt = postedAt
    ? postedAt.businessAdd(1).format('MMMM Do YYYY')
    : null;

  return (
    <Root>
      <Header>
        <LeftHeader>
          <h5>Merchant id: {settlement.merchantId}</h5>
          <h5>Merchant Store Name: {merchant?.merchantStoreName}</h5>
          <h5>From Date: {from.format('MMMM Do YYYY')}</h5>
          <h5>
            To Date:{' '}
            {toDate ? toDate.format('MMMM Do YYYY') : <Open>OPEN</Open>}
          </h5>
          <h5>
            Payout Date:{' '}
            {payoutAt ? (
              payoutAt === 'May 24th 2021' ? (
                'May 25th 2021'
              ) : (
                payoutAt
              )
            ) : (
              <Open>OPEN</Open>
            )}
          </h5>
          <h5>Transactions: {settlement.noTransactions}</h5>
          <h5>
            Fixed fee to settlement currency rate(EUR/GBP):{' '}
            {settlement.fixedFeeToSettlementCurrencyConversionRate}
          </h5>
          <h4>
            <u>
              {postedAt ? 'Payout amount: ' : 'Balance: '}
              {parsePrice(settlement.settlementAmount, settlement.currencyCode)}
            </u>
          </h4>
        </LeftHeader>
        <RightHeader>
          <h4>Summary</h4>
          <SmallDivider />
          <PaymentRow>
            <h5>Sales</h5>
            <h5>
              {parsePrice(
                settlement.transactionAmount,
                settlement.currencyCode,
              )}
            </h5>
          </PaymentRow>
          <PaymentRow>
            <h5>Fees</h5>
            <h5>
              {parseFee(
                settlement.settlementFeeAmount,
                settlement.currencyCode,
              )}
            </h5>
          </PaymentRow>
          <SmallDivider />
          <PaymentRow>
            <h5>Net</h5>
            <h5>
              <strong>{parsePrice(settlement.settlementAmount, 'GBP')}</strong>
            </h5>
          </PaymentRow>
          <br />
          <h4>
            <strong>Fees</strong>
          </h4>
          <SmallDivider />
          <PaymentRow>
            <h5>Trxn fees</h5>
            <h5>{parseFee(salesFeeAmount, 'GBP')}</h5>
          </PaymentRow>
          <PaymentRow>
            <h5>Other</h5>
            <h5>{parseFee(otherFeeAmount, 'GBP')}</h5>
          </PaymentRow>
          <SmallDivider />
          <PaymentRow>
            <h5>Total</h5>
            <h5>{parseFee(salesFeeAmount + otherFeeAmount, 'GBP')}</h5>
          </PaymentRow>
        </RightHeader>
      </Header>
      <Divider />
      <Table<Posting>
        loading={loading}
        size={'small'}
        dataSource={postings.sort((a, b) => (a.date <= b.date ? -1 : 1))}
        columns={columns}
        rowKey={(record) => record.transferId}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => console.log(record),
          };
        }}
      />
    </Root>
  );
};

/**
 * Exports
 */
export { SettlementDetails };

/**
 * Imports
 */
import { BaseLayout } from 'features/navigation';
import * as React from 'react';

import { SettlementList } from '../components';

/**
 * Types
 */
type SettlementPageProps = {};

/**
 * LoginPage component
 */
const SettlementPage: React.FC<SettlementPageProps> = () => {
  return (
    <BaseLayout>
      <SettlementList />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { SettlementPage };

const getMerchantApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('dev')) {
    return 'https://api.dev.vibrant.one';
  }
  if (hostname.includes('uat')) {
    return 'https://api.uat.vibrant.one';
  }
  if (hostname.includes('dashboard.vibrant.one')) {
    return 'https://api.vibrant.one';
  }
  // should be used for localhost
  return 'https://api.dev.vibrant.one';
};

export const MERCHANT_API_URL = getMerchantApiUrl();

const getConfig = () => {
  /**
   * UAT
   */
  if (MERCHANT_API_URL.includes('uat')) {
    console.log('uat');
    return {
      apiKey: 'AIzaSyDVDd4j87hxMN6VRnxKqV9Pv2ynjCDp_CI',
      authDomain: 'vibrant-fb-uat.firebaseapp.com',
      databaseURL: 'https://vibrant-fb-uat.firebaseio.com',
      projectId: 'vibrant-fb-uat',
      storageBucket: 'vibrant-fb-uat.appspot.com',
      bucketBankFiles: 'gs://vibrant-uat-bank-files',
      messagingSenderId: '922162229168',
      appId: '1:922162229168:web:1f0f9d186a732df15a7075',
      measurementId: 'G-J87DHCSWSP',
    };
  }
  /**
   * Production
   */
  if (MERCHANT_API_URL.includes('api.vibrant.one')) {
    console.log('prod');
    return {
      apiKey: 'AIzaSyAbIbKSw_SmtYM8WLqs9ZUoFsb07q7i28o',
      authDomain: 'vibrantly-production.firebaseapp.com',
      databaseURL: 'https://vibrantly-production.firebaseio.com',
      projectId: 'vibrantly-production',
      storageBucket: 'vibrantly-production.appspot.com',
      bucketBankFiles: 'gs://vibrant-prod-bank-files',
      messagingSenderId: '836686013166',
      appId: '1:836686013166:web:81fa0275e84f2c7ac409ff',
      measurementId: 'G-0QY83MKTS5',
    };
  }
  console.log('dev');
  /**
   * Localhost
   */
  return {
    apiKey: 'AIzaSyCv-etousHNny0jlIiLLkXopYTyBJbeBLY',
    authDomain: 'staging-727fe.firebaseapp.com',
    databaseURL: 'https://staging-727fe.firebaseio.com',
    projectId: 'staging-727fe',
    storageBucket: 'staging-727fe.appspot.com',
    bucketBankFiles: 'gs://vibrant-dev-bank-files',
    messagingSenderId: '400993474174',
    appId: '1:400993474174:web:09a2fe0849a85037d42a2f',
    measurementId: 'G-827N75XHQ6',
  };
};
export const FTP_API_URL = 'https://ftp-puller.vibrant.one';
export const FIREBASE_CONFIG = getConfig();

/**
 * Imports
 */
import * as React from 'react';

import { TransactionMain } from '../components';

import { useParams } from 'react-router-dom';
import { BaseLayout } from 'features/navigation';
/**
 * Types
 */
type MerchantPageProps = {};
type ParamTypes = {
  id: string;
};

/**
 * LoginPage component
 */
const TransactionPage: React.FC<MerchantPageProps> = () => {
  console.log('merchant page loaded');

  const { id } = useParams<ParamTypes>();

  return (
    <BaseLayout>
      <TransactionMain id={id} />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { TransactionPage };

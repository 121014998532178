import { createSlice } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { Merchant } from 'types';

interface MerchantState {
  merchants: Merchant[];
}

const initialState: MerchantState = { merchants: [] };

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    populateMerchants(state, action: VibrantlyAction<Merchant[]>) {
      state.merchants = action.payload.sort(
        (a, b) => parseInt(b.merchantId) - parseInt(a.merchantId),
      );
    },
  },
});

export const { populateMerchants } = merchantSlice.actions;
export default merchantSlice;

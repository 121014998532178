import { Transaction } from 'types';
import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { MERCHANT_API_URL } from 'config';
import { Moment } from 'moment';

export const getTransactions = async (
  merchantId: string,
  startDate: Moment,
  endDate: Moment,
  terminalId?: string,
): Promise<Transaction[]> => {
  const idToken = await getIdToken();
  try {
    const _params = {
      merchantId,
      startDate: startDate.clone().utc().format(),
      endDate: endDate.clone().utc().format(),
    };
    console.log(_params);
    const params = terminalId !== '' ? { ..._params, terminalId } : _params;
    const response = await axios.get(
      `${MERCHANT_API_URL}/api/v1/merchants/${merchantId}/transactions`,
      {
        params,
        headers: { token: idToken },
      },
    );
    return response.data.result as Transaction[];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllTransactions = async (
  startDate: Moment,
  endDate: Moment,
): Promise<Transaction[]> => {
  const idToken = await getIdToken();
  try {
    const params = {
      startDate: startDate.clone().utc().format(),
      endDate: endDate.clone().utc().format(),
    };
    const response = await axios.get(
      `${MERCHANT_API_URL}/api/v1/transactions`,
      {
        params,
        headers: { token: idToken },
      },
    );
    return response.data.result as Transaction[];
  } catch (error) {
    console.log(error);
    return [];
  }
};

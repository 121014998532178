/**
 * Imports
 */
import { BaseLayout } from 'features/navigation';
import { TransferFiles } from 'features/transfers/components';
import * as React from 'react';

import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface TransferPageProps {}

/**
 * TransferPage component
 */
const TransferPage: React.FC<TransferPageProps> = (_props) => {
  return (
    <BaseLayout>
      <TransferFiles />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { TransferPage };

/**
 * Imports
 */
import * as React from 'react';
import { Button, Modal, Card } from 'antd';
import { styled } from 'theme';
const { Meta } = Card;

/**
 * Types
 */
export interface ContactButtonProps {}

const ContactButtonStyled = styled(Button)`
  margin-right: 10px;
`;

const ContactContext = styled.div`
  margin-top: 20px;
`;

/**
 * LogoutButton component
 */
const ContactButton: React.FC<ContactButtonProps> = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  /**
   *  Handle logout
   */
  const _handleShowContact = async () => {
    setShowModal(true);
  };

  return (
    <>
      <Modal
        title="Contact Clearhaus"
        onCancel={() => setShowModal(false)}
        visible={showModal}
        footer={false}
      >
        <Card hoverable style={{ width: 460 }}>
          <Meta
            title="Anders Kaels Malmos - CTO"
            description={
              <div>
                <div>WhatsApp / mobile: +45 20703188</div>
                <div>email: akm@clearhaus.com</div>
              </div>
            }
          />
        </Card>
        <Card hoverable style={{ width: 460 }}>
          <Meta
            title="Kåre Brøgger Sørensen - CPO"
            description={
              <div>
                <div>WhatsApp / mobile: +45 60152694</div>
                <div>email: ks@clearhaus.com</div>
              </div>
            }
          />
        </Card>
        <ContactContext>
          Also please feel free to ask for access to our #slack channel
        </ContactContext>
      </Modal>
      <ContactButtonStyled type="primary" onClick={_handleShowContact}>
        Contact
      </ContactButtonStyled>
    </>
  );
};

/**
 * Exports
 */
export { ContactButton };

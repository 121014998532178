/**
 * Imports
 */
import Title from 'antd/lib/typography/Title';
import { Logo } from 'components';
import { TopBar } from 'features/navigation';
import { ContactButton, LogoutButton } from 'features/user/components';
import * as React from 'react';
import { styled } from 'theme';

/* Layout */
const Body = styled.div`
  padding: 24px;

  display: flex;
  flex: 1;
`;
const Main = styled.div`
  height: 100%;
  padding-top: 24px;

  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 600px) {
    padding: 24px 24px 0px 24px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.div`
  width: 100%;
  align-self: center;
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1;
  padding-bottom: 24px;
  background-color: ${(props) =>
    props.theme.Dashboard.Colors.Background.Primary};
  height: 80px;
`;

const LogoWrapper = styled.div`
  flex: 1;
`;

const TopText = styled.div`
  flex-grow: 1;
  margin: 0;
`;
const TableWrapper = styled.div`
  border-radius: 4px;
  /* th override */
  .ant-table-thead .ant-table-cell {
    background-color: white;
  }
  .ant-table-tbody .ant-table-cell {
    cursor: pointer;
  }
`;

/**
 * Types
 */
export interface BaseLayoutProps {}

const Header: React.FC = () => {
  return (
    <Top>
      <LogoWrapper>
        <Logo width={100} />
      </LogoWrapper>
      <TopText>
        <Title level={3}>Dashboard</Title>
      </TopText>
      <ContactButton />
      <LogoutButton />
    </Top>
  );
};

/**
 * BaseLayout component
 */
const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  return (
    <Body>
      <Column>
        <Header />
        <TopBar />
        <Main>
          <Content>
            <TableWrapper>{children}</TableWrapper>
          </Content>
        </Main>
      </Column>
    </Body>
  );
};

/**
 * Exports
 */
export { BaseLayout };

/**
 * Imports
 */
import { FileOutlined } from '@ant-design/icons';
import { List, Typography } from 'antd';
import { Spinner } from 'components';
import { useTransfers } from 'features/transfers/api';
import * as React from 'react';
import { styled } from 'theme';
const { Title, Link } = Typography;

/**
 * Styling
 */
const Root = styled.div``;
const Wrapper = styled.div`
  margin-bottom: 24px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;

  & .anticon {
    color: ${({ theme }) => theme.Dashboard.Colors.Base.Brand};
    margin-right: 12px;
  }
`;

/**
 * Types
 */
export interface TransferFilesProps {}

/**
 * TransferFiles component
 */
const TransferFiles: React.FC<TransferFilesProps> = (_props) => {
  const { bankFolders, isLoading } = useTransfers();

  if (isLoading) {
    return (
      <Root>
        <Spinner showSpinner />
      </Root>
    );
  }
  if (bankFolders.length === 0) {
    return <Root>No files</Root>;
  }

  const _download = (downloadUrl: string, name: string) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const blob = xhr.response;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
      URL.revokeObjectURL(link.href);
    };
    xhr.open('GET', downloadUrl);
    xhr.send();
  };

  return (
    <Root>
      {bankFolders.map((folder) => {
        const dateString = folder.date.toDateString();

        return (
          <Wrapper key={folder.folderName}>
            <Title level={5}>{dateString}</Title>
            <List bordered>
              {folder.files.map((file) => {
                return (
                  <List.Item key={file.fileName}>
                    <Row>
                      <FileOutlined />
                      <Link
                        onClick={() =>
                          _download(file.downloadUrl, file.fileName)
                        }
                      >
                        {file.fileName}
                      </Link>
                    </Row>
                  </List.Item>
                );
              })}
            </List>
          </Wrapper>
        );
      })}
    </Root>
  );
};

/**
 * Exports
 */
export { TransferFiles };

/**
 * Imports
 */
import * as React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { styled } from 'theme';

/**
 * Styles Components
 */
const ButtonWrapper = styled.div`
  margin-bottom: 24px;
`;

/**
 * Types
 */
export interface BackButtonProps {}

/**
 * BackButton component
 */
const BackButton: React.FC<BackButtonProps> = (props) => {
  /* History */
  const history = useHistory();

  /**
   *  Handle navigation
   */
  const _goBack = () => {
    history.goBack();
  };

  return (
    <ButtonWrapper>
      <Button onClick={_goBack} shape="round" icon={<ArrowLeftOutlined />}>
        Back
      </Button>
    </ButtonWrapper>
  );
};

/**
 * Exports
 */
export { BackButton };

import { Input, Modal, Select } from 'antd';
import { Spinner } from 'components';
import { upsertTerminal } from 'features/terminal/terminalAPI';
import { Terminal } from 'types';
/**
 * Imports
 */
import * as React from 'react';

import styled from 'styled-components';

const { Option } = Select;
const Label = styled.div`
  margin-bottom: 5px;
  margin-top: 5px;
`;
/**
 * Types
 */
type TerminalModalProps = {
  terminal: Terminal;
  show: boolean;
  merchantId: string;
  closeModal: () => void;
  fetchTerminals: () => void;
  modalType: 'create' | 'update';
};

/**
 * MerchantDetails component
 */
const TerminalModal: React.FC<TerminalModalProps> = ({
  terminal,
  fetchTerminals,
  show,
  closeModal,
  modalType,
}) => {
  const [terminalId, setTerminalId] = React.useState(terminal.id);
  const [descriptor, setDescriptor] = React.useState(terminal.descriptor);
  const [addressLine1, setAddressLine1] = React.useState(terminal.addressLine1);
  const [city, setCity] = React.useState(terminal.city);
  const [zip, setZip] = React.useState(terminal.zip);
  const [country, setCountry] = React.useState(terminal.country);
  const [serialNumber, setSerialNumber] = React.useState(terminal.serialNumber);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const _closeModal = () => {
    closeModal();
  };

  const onOk = async () => {
    if (loading) {
      return;
    }
    if (
      terminalId &&
      addressLine1 &&
      serialNumber &&
      descriptor &&
      city &&
      country
    ) {
      try {
        setLoading(true);
        await upsertTerminal(
          terminal.merchantId,
          terminalId,
          addressLine1,
          city,
          zip,
          country,
          descriptor,
          'W01',
          serialNumber,
          modalType,
        );
        fetchTerminals();
        _closeModal();
      } catch (e) {
        setLoading(false);
        setError('Update failed');
        setTimeout(() => setError(null), 4000);
      }
    } else {
      setError('Please give some input');
      setTimeout(() => setError(null), 2000);
    }
  };

  const isUpdate = modalType === 'update';
  const title = isUpdate ? 'Update Terminal' : 'Create Terminal';
  return (
    <Modal
      title={title}
      visible={show}
      onOk={onOk}
      onCancel={_closeModal}
      okText={isUpdate ? 'Update' : 'Create'}
      maskClosable={false}
      confirmLoading={loading}
    >
      <div>
        <Label>Terminal id</Label>
        <Input
          disabled={isUpdate}
          onChange={(event) => setTerminalId(event.target.value)}
          value={terminalId}
          placeholder="fx. 30310002"
        />
        <Label>Serial number</Label>
        <Input
          onChange={(event) => setSerialNumber(event.target.value)}
          value={serialNumber}
          placeholder="fx. xiasd344f"
        />
        <Label>Descriptor</Label>
        <Input
          onChange={(event) => setDescriptor(event.target.value)}
          value={descriptor}
          placeholder="Vibrant ApS - payment"
        />
        <Label>Address</Label>
        <Input
          onChange={(event) => setAddressLine1(event.target.value)}
          value={addressLine1}
          placeholder="London"
        />
        <Label>City</Label>
        <Input
          onChange={(event) => setCity(event.target.value)}
          value={city}
          placeholder="London"
        />
        <Label>Zip</Label>
        <Input
          onChange={(event) => setZip(event.target.value)}
          value={zip}
          placeholder="8855"
        />
        <Label>Country</Label>
        <Select
          defaultValue={country}
          onChange={(value) => setCountry(value)}
          style={{ width: 200 }}
        >
          <Option value="GB">United Kingdom</Option>
          <Option value="DK">Denmark</Option>
          <Option value="CY">Cyprus</Option>
          <Option value="NL">Netherland</Option>
          <Option value="ES">Spain</Option>
          <Option value="IE">Ireland</Option>
          <Option value="CH">Switzerland</Option>
          <Option value="BE">Belgium</Option>
          <Option value="CZ">Czech</Option>
          <Option value="AT">Austria</Option>
          <Option value="GR">Greece</Option>
          <Option value="LU">Luxembourg</Option>
          <Option value="DE">Germany</Option>
        </Select>
      </div>
      <Spinner showSpinner={loading} />
      {error && <div>{error}</div>}
    </Modal>
  );
};

/**
 * Exports
 */
export { TerminalModal };

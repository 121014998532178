import { Button, Typography, Divider, Descriptions } from 'antd';
import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { BackButton, Spinner } from 'components';
import { TerminalModal, TerminalList } from 'features/terminal';
import { Merchant, Terminal } from 'types';
import { getTerminals } from 'features/terminal/terminalAPI';
import { UpdateMerchantModal } from './UpdateMerchantModal';

import { useHistory } from 'react-router-dom';
/**
 * Imports
 */
import * as React from 'react';

import styled from 'styled-components';

import { getMerchantById } from '../merchantAPI';
import { ROUTES } from 'router';

const { Title } = Typography;
/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-right: 16px;
`;
const AddTerminalButton = styled(Button)``;
const EditMerchantButton = styled(Button)`
  max-width: 200px;
`;
const QueryButton = styled(Button)``;

const TableWrapper = styled.div`
  border-radius: 4px;
  /* th override */
  .ant-table-thead .ant-table-cell {
    background-color: white;
  }
  /* .ant-table-tbody .ant-table-cell {
    cursor: pointer;
  } */
`;

/**
 * Types
 */
type MerchantDetailsProps = {
  id: string;
};

/**
 * MerchantDetails component
 */
const MerchantDetails: React.FC<MerchantDetailsProps> = ({ id }) => {
  const [merchant, setMerchant] = React.useState<Merchant | null>(null);
  const [terminals, setTerminals] = React.useState<Terminal[]>([]);
  const [terminal, setTerminal] = React.useState<Terminal | null>(null);
  const [showUpdateMerchantModal, setShowUpdateMerchantModal] = React.useState<
    boolean
  >(false);

  const fetchTerminals = async () => {
    const _terminals = await getTerminals(id);
    setTerminals(_terminals);
  };
  const fetchMerchant = async () => {
    const merchant = await getMerchantById(id);
    setMerchant(merchant);
  };
  React.useEffect(() => {
    fetchTerminals();
    fetchMerchant();
    // eslint-disable-next-line
  }, []);

  const history = useHistory();
  const _closeTerminalModal = () => {
    setTerminal(null);
  };
  const _showTerminalModal = () => {
    if (merchant) {
      const t: Terminal = {
        id: '',
        merchantId: merchant.merchantId,
        addressLine1: '',
        city: '',
        zip: '',
        descriptor: '',
        serialNumber: '',
        country: 'GB',
        type: 'W01',
        active: true,
        createdBy: '',
        createdAt: 0,
      };
      setTerminal(t);
    }
  };

  const _showUpdateMerchant = () => {
    setShowUpdateMerchantModal(true);
  };

  const closeMerchantUpdateModal = () => {
    setShowUpdateMerchantModal(false);
  };

  if (!id || !merchant)
    return (
      <Root>
        <Spinner showSpinner={true} />
      </Root>
    );

  const _showTransactions = () => {
    history.push(ROUTES.merchantsTransactions.path(merchant.merchantId));
  };

  return (
    <Root>
      <BackButton />
      <Descriptions
        title={
          'Merchant legal name : ' +
          merchant.merchantName +
          ' (' +
          merchant.country +
          ')'
        }
      >
        <Descriptions.Item label="Merchant Store Name">
          {merchant.merchantStoreName}
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          {merchant.addressLine1}, {merchant.zip}, {merchant.city}
        </Descriptions.Item>
        <Descriptions.Item label="Country code">
          {merchant.country}
        </Descriptions.Item>
        <Descriptions.Item label="Currency code">
          {merchant.currency}
        </Descriptions.Item>
        <Descriptions.Item label="Clearhaus Id">
          {merchant.clearhausMerchantId}
        </Descriptions.Item>
        <Descriptions.Item label="Registration number">
          {merchant.registrationNumber}
        </Descriptions.Item>
        <Descriptions.Item label="IBAN">{merchant.iban}</Descriptions.Item>
        <Descriptions.Item label="BIC">{merchant.bic}</Descriptions.Item>
        <Descriptions.Item label="MCC">{merchant.mcc}</Descriptions.Item>
        <Descriptions.Item label="Contact">
          {merchant.contactName + ', ' + merchant.contactEmail}
        </Descriptions.Item>
      </Descriptions>
      <EditMerchantButton icon={<EditOutlined />} onClick={_showUpdateMerchant}>
        Edit Merchant
      </EditMerchantButton>
      <Divider />
      <ButtonRow>
        <ButtonWrapper>
          <AddTerminalButton
            icon={<PlusOutlined />}
            onClick={_showTerminalModal}
          >
            Add Terminal
          </AddTerminalButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <QueryButton
            icon={<SearchOutlined />}
            onClick={_showTransactions}
            type="primary"
          >
            Query Transactions
          </QueryButton>
        </ButtonWrapper>
      </ButtonRow>
      <Divider />
      <Title level={5}>Terminals</Title>
      <TableWrapper>
        <TerminalList
          fetchTerminals={fetchTerminals}
          terminals={terminals}
          merchant={merchant}
        />
      </TableWrapper>

      {terminal && (
        <TerminalModal
          modalType={'create'}
          fetchTerminals={fetchTerminals}
          merchantId={merchant.merchantId}
          show={terminal !== null}
          closeModal={_closeTerminalModal}
          terminal={terminal}
        />
      )}
      {showUpdateMerchantModal && (
        <UpdateMerchantModal
          fetchMerchant={fetchMerchant}
          merchant={merchant}
          show={showUpdateMerchantModal}
          closeModal={closeMerchantUpdateModal}
        />
      )}
    </Root>
  );
};

/**
 * Exports
 */
export { MerchantDetails };

/**
 * Imports
 */
import { MerchantTable } from 'features/merchant';
import { BaseLayout } from 'features/navigation';
import * as React from 'react';

/**
 * Styling
 */

/**
 * Types
 */
export interface HomePageProps {}

/**
 * HomePage component
 */
const HomePage: React.FC<HomePageProps> = (_props) => {
  return (
    <BaseLayout>
      <MerchantTable />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { HomePage };

/**
 * Imports
 */
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider, ProviderProps } from 'react-redux';
import { ThemeProvider } from 'theme';

import { Spinner } from 'components';
import { RootRouter } from 'router';

import { storePromise } from './store';

import './index.css';
// Antd style overrride
import './components/Antd/less/antd-overrides.less';

/**
 * `App` component
 */
const App = () => {
  const [store, setStore] = React.useState<ProviderProps['store'] | null>(null);

  React.useEffect(() => {
    storePromise
      .then((_store) => setStore(_store))
      .catch(() => console.log('Error occurred getting store ready'));
  }, []);

  if (!store) {
    return <Spinner showSpinner />;
  }

  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider>
          <RootRouter />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

/**
 * Entry point
 */
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/**
 * Imports
 */
import { BaseLayout } from 'features/navigation';
import * as React from 'react';

import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface ReportsPageProps {}

/**
 * ReportsPage component
 */
const ReportsPage: React.FC<ReportsPageProps> = (_props) => {
  return <BaseLayout>ReportsPage</BaseLayout>;
};

/**
 * Exports
 */
export { ReportsPage };

/**
 * Imports
 */
import { Transaction } from 'types';
import { Tooltip, Button, Table, Modal } from 'antd';
import * as React from 'react';
import moment from 'moment-timezone';
import { Statistic } from 'antd';

import styled from 'styled-components';

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;
const RowLeft = styled(Row)`
  justify-content: space-around;
  padding-bottom: 20px;
`;
const SuccessResponse = styled.div`
  color: #52c41a;
  font-weight: bold;
`;
const ErrorResponse = styled.div`
  color: red;
  font-weight: bold;
`;
/**
 * Types
 */
type TerminalListProps = {
  transactions: Transaction[];
  loading: boolean;
};

/**
 * MerchantDetails component
 */
const TransactionList: React.FC<TerminalListProps> = ({
  loading,
  transactions,
}) => {
  const [transaction, setTransaction] = React.useState<Transaction | null>(
    null,
  );

  const getLocalTime = (t: Transaction): string => {
    const utcTime = t.utcTime;
    const utc = moment.utc(utcTime).clone().local();
    return utc.format('YYYY-MM-DD HH:mm:ss');
  };

  const columns = [
    {
      title: 'Trxn id',
      dataIndex: 'txnGuid',
      key: 'txnGuid',
    },
    {
      title: 'Date',
      key: 'trxnTime',
      render: (_text: any, record: Transaction) => getLocalTime(record),
    },
    {
      title: 'CardNo',
      dataIndex: 'cardNo',
      key: 'cardNo',
    },
    {
      title: 'Card Source',
      dataIndex: 'cardSource',
      key: 'cardSource',
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (_text: any, record: Transaction) => {
        const currencyConverter = (cur: number) => {
          if (cur === 826) {
            return 'GBP';
          }
          if (cur === 978) {
            return 'EUR';
          }
          if (cur === 208) {
            return 'DKK';
          }
          if (cur === 578) {
            return 'NOK';
          }
          if (cur === 203) {
            return 'CZK';
          }
          return 'NA';
        };
        const cur = currencyConverter(record.transactionCurrency);
        return `${record.transactionAmount} ${cur}`;
      },
    },
    {
      title: 'Response Code',
      key: 'responseKode',
      render: (_text: any, record: Transaction) => {
        const respCode = record.responseCode;
        const trxnStatus = record.transactionStatus;
        return respCode === '00' && trxnStatus === 'N' ? (
          <SuccessResponse>{respCode}</SuccessResponse>
        ) : (
          <ErrorResponse>{respCode}</ErrorResponse>
        );
      },
    },
    {
      title: 'Resp. Desc.',
      key: 'responseDescription',
      render: (_text: any, record: Transaction) => {
        const respCode = record.responseCode;
        const trxnStatus = record.transactionStatus;
        return respCode === '00' && trxnStatus === 'N' ? (
          <SuccessResponse>{record.responseDescription}</SuccessResponse>
        ) : (
          <ErrorResponse>{record.responseDescription}</ErrorResponse>
        );
      },
    },
    {
      title: 'Trxn status',
      dataIndex: 'transactionStatus',
      key: 'transactionStatus',
    },
    {
      title: 'Auth Code',
      dataIndex: 'authCode',
      key: 'authCode',
    },
    {
      title: 'RRN',
      dataIndex: 'rrn',
      key: 'rrn',
    },
    { title: 'Terminal id', dataIndex: 'terminalCode', key: 'terminalCode' },
    {
      title: '',
      key: 'action',
      render: (_text: any, record: Transaction) => (
        <Button
          onClick={() => setTransaction(record)}
          type="primary"
          size="middle"
        >
          Details
        </Button>
      ),
    },
  ];
  const filteredTransactions = transactions;
  return (
    <Root>
      <RowLeft>
        <Statistic title="All trxns" value={filteredTransactions.length} />
        <Statistic
          title="Success trxns"
          valueStyle={{ color: '#3f8600' }}
          value={
            filteredTransactions.filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved',
            ).length
          }
        />
        <Statistic
          title="GBP"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 826, // GBP
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="EUR"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 978,
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="DKK"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 208,
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="NOK"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 578,
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="CZK"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 203,
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="Failed trxns"
          valueStyle={{ color: '#cf1322' }}
          value={
            filteredTransactions.filter(
              (t) =>
                t.responseCode !== '00' ||
                t.transactionStatus !== 'N' ||
                t.responseDescription !== 'Approved',
            ).length
          }
        />
        <Statistic
          title="Card errors (05)"
          valueStyle={{ color: '#cf1322' }}
          value={
            filteredTransactions.filter((t) => t.responseCode === '05').length
          }
        />
        <Statistic
          title="Credit card rejections (58)"
          valueStyle={{ color: '#cf1322' }}
          value={
            filteredTransactions.filter((t) => t.responseCode === '58').length
          }
        />
      </RowLeft>
      <Table
        columns={columns}
        dataSource={transactions}
        size={'small'}
        loading={loading}
        rowKey={(record) => record.txnGuid}
      />
      <Modal
        width={300}
        onCancel={() => setTransaction(null)}
        title="Transaction details"
        visible={transaction !== null}
        footer={null}
      >
        <Root>
          <Row>
            <p>TxnGuid:</p>
            <p>{transaction?.txnGuid}</p>
          </Row>
          <Row>
            <p>CardNo:</p>
            <p>{transaction?.cardNo}</p>
          </Row>
          <Row>
            <Tooltip title="Card type of txn (C: Credit, D: Debit, P: Prepaid)">
              <p>CardDci:</p>
            </Tooltip>
            <p>{transaction?.cardDci}</p>
          </Row>
          <Row>
            <Tooltip title="Card source of txn (V: Visa, M: Mastercard)">
              <p>CardSource:</p>
            </Tooltip>
            <p>{transaction?.cardSource}</p>
          </Row>
          <Row>
            <p>Request Date:</p>
            <p>{transaction?.requestDate.substr(0, 10)}</p>
          </Row>
          <Row>
            <p>Request Time:</p>
            <p>{transaction?.requestTime}</p>
          </Row>
          <Row>
            <p>Reponse Code:</p>
            <p>{transaction?.responseCode}</p>
          </Row>
          <Row>
            <p>Reponse Description:</p>
            <p>{transaction?.responseDescription}</p>
          </Row>
          <Row>
            <p>Trxn name:</p>
            <p>{transaction?.transactionName}</p>
          </Row>
          <Row>
            <p>Trxn amount:</p>
            <p>{transaction?.transactionAmount}</p>
          </Row>
          <Row>
            <p>Trxn currency:</p>
            <p>{transaction?.transactionCurrency}</p>
          </Row>
          <Row>
            <Tooltip title="Transaction status A: Advice, S: Suspicious, F: Reject, R: Reversed, V: Void, N: Normal, I: Invalid, P: Problem">
              <p>Trxn status:</p>
            </Tooltip>
            <p>{transaction?.transactionStatus}</p>
          </Row>
          <Row>
            <Tooltip title="Transaction effect(C:credit,D: debit)">
              <p>Trxn Effect:</p>
            </Tooltip>
            <p>{transaction?.txnEffect}</p>
          </Row>
          <Row>
            <Tooltip title="Entry type of transaction(C: Chip, T:Contactless, L:Manual, etc.. )">
              <p>Trxn Entry:</p>
            </Tooltip>
            <p>{transaction?.txnEntry}</p>
          </Row>
          <Row>
            <p>Terminal id:</p>
            <p>{transaction?.terminalCode}</p>
          </Row>
        </Root>
      </Modal>
    </Root>
  );
};

/**
 * Exports
 */
export { TransactionList };

import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { Merchant } from 'types';
import { MERCHANT_API_URL } from 'config';

const getMerchants = async (): Promise<Merchant[]> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(`${MERCHANT_API_URL}/api/v1/merchants`, {
      headers: { token: idToken },
    });
    return response.data.result as Merchant[];
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getMerchantById = async (mID: string): Promise<Merchant | null> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(
      `${MERCHANT_API_URL}/api/v1/merchants/${mID}`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Merchant;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const upsertMerchant = async (
  operation: string,
  merchantId: string | null,
  clearhausId: string,
  merchantStoreName: string,
  merchantName: string,
  registrationNumber: string,
  descriptor: string,
  iban: string,
  bic: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  zip: string,
  countryCode: string,
  timezone: string,
  name: string,
  email: string,
  phone: string,
  mcc: string,
  currencyCode: string,
  merchantContract: string,
): Promise<boolean> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.post(
      `${MERCHANT_API_URL}/api/v1/merchants/${operation}`,
      {
        merchantId,
        clearhausId,
        merchantStoreName,
        merchantName,
        registrationNumber,
        descriptor,
        mcc,
        currency: currencyCode,
        merchantContract,
        address: {
          addressLine1,
          addressLine2: addressLine2 !== '' ? addressLine2 : null,
          city,
          zip,
          country: countryCode,
          timezone,
        },
        contact: {
          name,
          email,
          phone,
        },
        bank: {
          iban,
          bic,
        },
      },
      { headers: { token: idToken } },
    );
    const { success } = response.data;
    if (success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export { upsertMerchant, getMerchants, getMerchantById };

import { Settlement, Posting } from 'types';
import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { MERCHANT_API_URL } from 'config';

export const getSettlements = async (): Promise<Settlement[]> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(`${MERCHANT_API_URL}/api/v1/settlements`, {
      headers: { token: idToken },
    });
    return response.data.result as Settlement[];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getSettlementById = async (
  settlementId: string,
): Promise<Settlement | null> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(
      `${MERCHANT_API_URL}/api/v1/merchant/transfers/${settlementId}`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Settlement;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPostings = async (settlementId: string): Promise<Posting[]> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(
      `${MERCHANT_API_URL}/api/v1/merchant/transfers/${settlementId}/postings`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Posting[];
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Imports
 */
import { hasFinanceSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router';
import styled from 'styled-components';

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex-direction: row;
`;

const NavDiv = styled.div`
  border-radius: 4px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #106c5b;
  a {
    color: white;
    font-size: 20px;
    padding: 0 12px;
    font-weight: bold;
  }
`;

/**
 * Types
 */
type TopBarProps = object;

/**
 * LoginPage component
 */
const TopBar: React.FC<TopBarProps> = () => {
  return (
    <Root>
      <NavDiv>
        <div>
          <Link to={ROUTES.home.route}>Merchants</Link>
          <Link to={ROUTES.settlements.route}>Settlements</Link>
          <Link to={ROUTES.transactions.route}>Transactions</Link>
          {/** hasFinance && <Link to={ROUTES.transfers.route}>Transfers</Link> */}
        </div>
        <div />
      </NavDiv>
    </Root>
  );
};

/**
 * Exports
 */
export { TopBar };

/**
 * Imports
 */
import { Input, Button, Alert } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import userFeature from 'features/user';

import { firebaseLogin } from 'firebaseAPI';
import { Logo } from 'components';

import styled from 'styled-components';

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

/**
 * Types
 */
type LoginPageProps = {};

/**
 * LoginPage component
 */
const LoginPage: React.FC<LoginPageProps> = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = !!useSelector(
    userFeature.redux.selectors.maybeUserSelector,
  );

  React.useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(false);
      history.replace({ pathname: '/' });
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const _handleLogin = async () => {
    try {
      setIsLoading(true);
      const user = await firebaseLogin(email, password);
      dispatch(userFeature.redux.actions.userUpdated(user));
      history.replace({ pathname: '/' });
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  const _handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleLogin();
    }
  };

  return (
    <Root>
      <LoginContent>
        <h1>POS Support Login</h1>
        <Logo width={100} />
        <Row>
          <Input
            placeholder="Email address"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={_handleKeyDown}
          />
        </Row>
        <Row>
          <Input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={_handleKeyDown}
          />
        </Row>
        <Button type="primary" onClick={_handleLogin} loading={isLoading}>
          Login
        </Button>
        {errorMessage && <Alert type="error" message={errorMessage} />}
      </LoginContent>
    </Root>
  );
};

/**
 * Exports
 */
export { LoginPage };

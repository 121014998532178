/**
 * Imports
 */
import { Settlement } from 'types';
import { DatePicker, Button, Table, Statistic } from 'antd';
import * as React from 'react';
import moment, { Moment } from 'moment-business-days';
import { getSettlements } from '../settlementAPI';
import { getMerchants } from 'features/merchant/merchantAPI';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { ROUTES } from 'router';

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Open = styled.span`
  color: #52c41a;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 380px;
`;

/**
 * Types
 */
type SettlementListProps = object;
const parsePrice = (amount: number, currency: string) => {
  return new Intl.NumberFormat('gb-UK', {
    style: 'currency',
    currency: currency,
  }).format(amount / 100);
};

type EnrichedSettlement = Settlement & {
  merchantStoreName: string | undefined;
  merchantName: string | undefined;
};

/**
 * MerchantDetails component
 */
const SettlementList: React.FC<SettlementListProps> = () => {
  const [settlements, setSettlements] = React.useState<EnrichedSettlement[]>(
    [],
  );
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const history = useHistory();

  const fetchSettlements = async () => {
    const data = await getSettlements();
    const merchants = await getMerchants();
    const settlementData = data.map((s) => {
      const merchantId = s.merchantId;
      const merchant = merchants.find((m) => m.merchantId === merchantId);
      return {
        ...s,
        merchantStoreName: merchant?.merchantStoreName,
        merchantName: merchant?.merchantName,
      };
    });
    setSettlements(settlementData);
    setLoading(false);
  };
  const getFormat = (
    currency: 'GBP' | 'EUR' | 'DKK' | 'CZK',
  ):
    | { currency: 'GBP'; format: 'gb-UK' }
    | { currency: 'EUR'; format: 'de-DE' }
    | { currency: 'CZK'; format: 'cz-CZ' }
    | { currency: 'DKK'; format: 'dk-DK' } => {
    if (currency === 'EUR') {
      return {
        currency: 'EUR',
        format: 'de-DE',
      };
    }
    if (currency === 'DKK') {
      return {
        currency: 'DKK',
        format: 'dk-DK',
      };
    }
    if (currency === 'CZK') {
      return {
        currency: 'CZK',
        format: 'cz-CZ',
      };
    }
    return {
      currency: 'GBP',
      format: 'gb-UK',
    };
  };

  React.useEffect(() => {
    fetchSettlements();
    // eslint-disable-next-line
  }, []);

  const onDateChange = (value: any) => {
    setSelectedDate(value);
  };

  const openSettlements = settlements
    .filter((s) => !s.postedAt)
    .sort((a, b) => b.settlementAmount - a.settlementAmount);
  const closedSettlements = settlements
    .filter((s) => s.postedAt)
    .sort((a, b) => b.postedAt - a.postedAt)
    .filter((s) => {
      if (!selectedDate) {
        return s;
      }
      const date = moment.unix(s.postedAt).utc().local();
      return (
        date.businessAdd(1).format('YYYYMMDD') ===
        selectedDate.format('YYYYMMDD')
      );
    });
  const columns = [
    {
      title: 'Payout date',
      render: (_text: any, record: Settlement) => {
        const postedAt = record.postedAt;
        if (postedAt) {
          const date = moment.unix(postedAt).utc().local();
          const payoutAt = date.businessAdd(1).format('MMM Do');
          return payoutAt === 'May 24th 21' ? 'May 25th 21' : payoutAt;
        } else {
          return <Open>OPEN</Open>;
        }
      },
    },
    {
      title: 'Start date',
      render: (_text: any, record: Settlement) => {
        return moment.unix(record.startedAt).utc().local().format('MMM Do');
      },
    },
    {
      title: 'End date',
      render: (_text: any, record: Settlement) => {
        const postedAt = record.postedAt;
        if (postedAt) {
          const from = moment.unix(record.startedAt).utc().local();
          const to = moment.unix(postedAt).utc().local().subtract(1, 'days');
          const endDate = from > to ? from : to;
          return endDate.format('MMM Do');
        } else {
          return <Open>OPEN</Open>;
        }
      },
    },
    {
      title: 'Sales',
      render: (_text: any, record: Settlement) => {
        return parsePrice(record.transactionAmount, record.currencyCode);
      },
    },
    {
      title: 'Fees',
      render: (_text: any, record: Settlement) => {
        return parsePrice(record.settlementFeeAmount, record.currencyCode);
      },
    },
    {
      title: 'Payout amount',
      render: (_text: any, record: Settlement) => {
        const amount = record.settlementAmount;
        const { currency, format } = getFormat(record.currencyCode);
        return new Intl.NumberFormat(format, {
          style: 'currency',
          currency,
        }).format(amount / 100);
      },
    },
    {
      title: 'No Transactions',
      dataIndex: 'noTransactions',
      key: 'noTransactions',
    },
    {
      title: 'Merchant Id',
      dataIndex: 'merchantId',
      key: 'merchantId',
    },
    {
      title: 'Merchant Legal Name',
      render: (_text: any, record: EnrichedSettlement) => record.merchantName,
    },
    {
      title: 'Merchant Store Name',
      render: (_text: any, record: EnrichedSettlement) => {
        return (
          <Link to={ROUTES.merchants.path(record.merchantId)}>
            {record.merchantStoreName}
          </Link>
        );
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
    },
    {
      title: '',
      render: (_text: any, record: Settlement) => {
        return (
          <Button
            onClick={() => {
              history.push(ROUTES.settlementDetails.path(record.transferId));
            }}
          >
            Details
          </Button>
        );
      },
    },
  ];
  return (
    <Root>
      <h2>Closed settlements</h2>
      <Statistic title="Closed Settlements" value={closedSettlements.length} />
      <Row>
        Select Payout Date:
        <DatePicker onChange={onDateChange} />
        <Button onClick={() => setSelectedDate(null)}>Clear date</Button>
      </Row>
      <Table
        columns={columns}
        dataSource={closedSettlements}
        size={'small'}
        loading={loading}
        rowKey={(record) => record.transferId}
      />
      <h2>Open settlements</h2>
      <Statistic title="Open Settlements" value={openSettlements.length} />
      <Table
        columns={columns}
        dataSource={openSettlements}
        size={'small'}
        loading={loading}
        rowKey={(record) => record.transferId}
      />
    </Root>
  );
};

/**
 * Exports
 */
export { SettlementList };

/**
 * Imports
 */
import * as React from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SettlementDetails } from '../components';
import { Settlement, Posting } from 'types';
import { getSettlementById, getPostings } from '../settlementAPI';
import { Spinner } from 'components/Spinner';
import { BaseLayout } from 'features/navigation';

/**
 * Types
 */
type SettlementDetailsPageProps = {};
type ParamTypes = {
  settlementId: string;
};
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
/**
 * LoginPage component
 */
const SettlementDetailsPage: React.FC<SettlementDetailsPageProps> = () => {
  const { settlementId } = useParams<ParamTypes>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [postings, setPostings] = React.useState<Posting[] | null>(null);
  const [settlement, setTransfer] = React.useState<Settlement | null>(null);

  const fetchTransfer = async () => {
    const data = await getSettlementById(settlementId);
    if (data) {
      setTransfer(data);
    }
  };
  const fetchPostings = async () => {
    const data = await getPostings(settlementId);
    if (data) {
      setPostings(data);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchPostings();
    fetchTransfer();
    // eslint-disable-next-line
  }, []);

  if (!postings || !settlement)
    return (
      <Root>
        <Spinner showSpinner={true} />
      </Root>
    );
  return (
    <BaseLayout>
      <SettlementDetails
        settlement={settlement}
        loading={loading}
        postings={postings}
      />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { SettlementDetailsPage };

/**
 * Imports
 */
import { Transaction, Merchant } from 'types';
import {
  Input,
  Tooltip,
  Button,
  Table,
  Modal,
  DatePicker,
  Space,
  Select,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { Statistic } from 'antd';
import { CSVLink } from 'react-csv';

import * as React from 'react';

import moment, { Moment } from 'moment-timezone';

import styled from 'styled-components';
import { getAllTransactions } from 'features/transaction/transactionAPI';
import { ConsoleSqlOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ROUTES } from 'router';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const GotoMerchant = styled.div`
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => props.theme.Dashboard.Colors.Base.Orange};
`;
const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;
const CSVRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;
const RowLeft = styled(Row)`
  justify-content: space-around;
  padding-bottom: 20px;
`;
const RowMerchantSelect = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;
const QueryHeader = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 20px;
`;
const SelectDate = styled.div`
  display: flex;
  margin-right: 5px;
  margin-left: 15px;
`;
const Last4 = styled(Input)`
  width: 100px;
`;
const SuccessResponse = styled.div`
  color: #52c41a;
  font-weight: bold;
`;
const ErrorResponse = styled.div`
  color: red;
  font-weight: bold;
`;
/**
 * Types
 */
type AllTransactionsListProps = {
  merchants: Merchant[];
};

/**
 * MerchantDetails component
 */
const SupportTransactionList: React.FC<AllTransactionsListProps> = ({
  merchants,
}) => {
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const [transaction, setTransaction] = React.useState<Transaction | null>(
    null,
  );
  const [filterValue, setFilterValue] = React.useState('');
  const [merchant, setMerchant] = React.useState<Merchant | null>(null);
  const [location, setLocation] = React.useState<Merchant | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [startDate, setStartDate] = React.useState<Moment>(
    moment().startOf('day'),
  );
  const [endDate, setEndDate] = React.useState<Moment>(moment().endOf('day'));

  const getLocalTime = (t: Transaction): string => {
    const utcTime = t.utcTime;
    const utc = moment.utc(utcTime).clone().local();
    return utc.format('YYYY-MM-DD HH:mm:ss');
  };

  const onOk = (dates: any) => {
    const [startDate, endDate] = dates as Moment[];
    setStartDate(startDate.clone());
    setEndDate(endDate.clone());
  };

  const mapStatus = (key: string) => {
    const m: any = {
      A: 'Advice',
      S: 'Suspicious',
      F: 'Reject',
      R: 'Reversed',
      V: 'Void',
      N: 'Normal',
      I: 'Invalid',
      P: 'Problem',
    };
    return m[key];
  };

  const search = async () => {
    if (startDate && endDate) {
      setLoading(true);
      const ts = await getAllTransactions(startDate, endDate);
      setLoading(false);
      setTransactions(ts);
    }
  };

  const history = useHistory();

  React.useEffect(() => {
    search();
    //eslint-disable-next-line
  }, []);

  const selectedMerchantIds = merchant
    ? merchants
        .filter((m) => m.clearhausMerchantId === merchant.clearhausMerchantId)
        .map((m) => m.merchantId)
    : [];
  const _filteredTransactions =
    selectedMerchantIds.length === 0
      ? transactions
      : transactions.filter((t) =>
          selectedMerchantIds.includes(t.merchantCode ? t.merchantCode : ''),
        );
  const _filteredByLocationTransactions = location
    ? _filteredTransactions.filter(
        (t) => location.merchantId === t.merchantCode,
      )
    : _filteredTransactions;
  const filteredTransactions = _filteredByLocationTransactions
    .filter((t) => {
      const cardNo = t.cardNo ? t.cardNo : '';
      const last4 = cardNo.substr(cardNo.length - 4);
      return last4.includes(filterValue) || filterValue === '';
    })
    .sort((a, b) => moment(b.utcTime).unix() - moment(a.utcTime).unix());
  const columns = [
    {
      title: 'Trxn id',
      dataIndex: 'txnGuid',
      key: 'txnGuid',
    },
    {
      title: 'Date',
      key: 'trxnTime',
      render: (_text: any, record: Transaction) => getLocalTime(record),
    },
    {
      title: 'CardNo',
      dataIndex: 'cardNo',
      key: 'cardNo',
    },
    {
      title: 'Card Source',
      dataIndex: 'cardSource',
      key: 'cardSource',
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (_text: any, record: Transaction) => {
        const currencyConverter = (cur: number) => {
          if (cur === 826) {
            return 'GBP';
          }
          if (cur === 978) {
            return 'EUR';
          }
          if (cur === 208) {
            return 'DKK';
          }
          if (cur === 578) {
            return 'NOK';
          }
          if (cur === 203) {
            return 'CZK';
          }
          return 'NA';
        };
        const cur = currencyConverter(record.transactionCurrency);
        return `${record.transactionAmount} ${cur}`;
      },
    },
    {
      title: <div>Resp. Code</div>,
      key: 'responseCode',
      sorter: {
        compare: (a: any, b: any) => a.responseCode - b.responseCode,
      },
      render: (_text: any, record: Transaction) => {
        const respCode = record.responseCode;
        const trxnStatus = record.transactionStatus;
        const mapper: any = {
          '58': '58 is typically a creditcard error',
          '05': '05 do not honor - issuer rejected the card',
        };
        const title = mapper[respCode] ? mapper[respCode] : null;
        return respCode === '00' && trxnStatus === 'N' ? (
          <SuccessResponse>{respCode}</SuccessResponse>
        ) : (
          <ErrorResponse>
            {respCode + ' '}
            {title ? (
              <Tooltip title={title}>
                <InfoCircleOutlined />
              </Tooltip>
            ) : null}
          </ErrorResponse>
        );
      },
    },
    {
      title: 'Resp. Desc.',
      key: 'responseDescription',
      render: (_text: any, record: Transaction) => {
        const respCode = record.responseCode;
        const trxnStatus = record.transactionStatus;
        const shortendDesc =
          record.responseDescription && record.responseDescription.length > 15
            ? record.responseDescription.substring(0, 15) + '...'
            : record.responseDescription;
        return respCode === '00' && trxnStatus === 'N' ? (
          <Tooltip title={record.responseDescription}>
            <SuccessResponse>{shortendDesc}</SuccessResponse>
          </Tooltip>
        ) : (
          <Tooltip title={record.responseDescription}>
            <ErrorResponse>{shortendDesc}</ErrorResponse>
          </Tooltip>
        );
      },
    },
    {
      title: 'Trxn status',
      key: 'transactionStatus',
      render: (_text: any, record: Transaction) =>
        mapStatus(record.transactionStatus),
    },
    {
      title: 'Trxn type',
      dataIndex: 'txnEffect',
      key: 'txnEffect',
    },
    {
      title: 'Auth Code',
      dataIndex: 'authCode',
      key: 'authCode',
    },
    {
      title: 'RRN',
      dataIndex: 'rrn',
      key: 'rrn',
    },
    {
      title: 'Terminal id',
      sorter: {
        compare: (a: any, b: any) => a.terminalCode - b.terminalCode,
      },
      dataIndex: 'terminalCode',
      key: 'terminalCode',
    },
    {
      title: 'Merchant id',
      sorter: {
        compare: (a: any, b: any) => a.merchantCode - b.merchantCode,
      },
      dataIndex: 'merchantCode',
      key: 'merchantCode',
    },
    {
      title: 'Merchant Name',
      key: 'merchantCode',
      render: (_text: any, record: Transaction) => {
        const merchantCode = record.merchantCode;
        const merchant = merchants.find((m) => m.merchantId === merchantCode);
        return merchant?.merchantName;
      },
    },
    {
      title: 'Merchant store name',
      sorter: {
        compare: (a: any, b: any) => a.merchantCode - b.merchantCode,
      },
      key: 'merchantCode',
      render: (_text: any, record: Transaction) => {
        const merchantCode = record.merchantCode;
        const merchant = merchants.find((m) => m.merchantId === merchantCode);
        return (
          <GotoMerchant
            onClick={() => {
              history.push(ROUTES.merchants.path(merchantCode));
            }}
          >
            {merchant?.merchantStoreName}
          </GotoMerchant>
        );
      },
    },
    {
      title: '',
      key: 'action',
      render: (_text: any, record: Transaction) => (
        <Button
          onClick={() => setTransaction(record)}
          type="primary"
          size="middle"
        >
          Details
        </Button>
      ),
    },
  ];
  const selectLocation = (merchantId: string) => {
    const _m = merchants.find((m) => m.merchantId === merchantId);
    if (_m) {
      setLocation(_m);
    } else {
      setLocation(null);
    }
  };
  const selectMerchant = (clearhausMerchantId: string) => {
    const _m = merchants.find(
      (m) => m.clearhausMerchantId === clearhausMerchantId,
    );
    if (_m) {
      setMerchant(_m);
    } else {
      setMerchant(null);
    }
  };
  const onlyHighLevelMerchants = merchants.reduce(
    (acc: Merchant[], m: Merchant) => {
      if (
        acc.find((_m) => _m.clearhausMerchantId === m.clearhausMerchantId) ||
        m.merchantName === 'PayCore'
      ) {
        return acc;
      }
      return acc.concat([m]);
    },
    [],
  );

  return (
    <Root>
      <QueryHeader>
        <SelectDate>Select Date Range</SelectDate>
        <Space direction="vertical" size={12}>
          <RangePicker
            defaultValue={[startDate, endDate]}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={onOk}
            onChange={onOk}
          />
        </Space>
        <Button onClick={search} type="primary">
          Search Transactions
        </Button>
      </QueryHeader>
      <RowMerchantSelect>
        <SelectDate>Select Merchant By name</SelectDate>
        <Select
          style={{ minWidth: 400 }}
          size="large"
          placeholder="All"
          onChange={selectMerchant}
        >
          <Option value="all">All</Option>
          {onlyHighLevelMerchants
            .reverse()
            .sort((a, b) => (a.merchantName > b.merchantName ? 1 : -1))
            .map((m: Merchant) => (
              <Option key={m.clearhausMerchantId} value={m.clearhausMerchantId}>
                {m.merchantName}
              </Option>
            ))}
        </Select>
      </RowMerchantSelect>
      <RowMerchantSelect>
        <SelectDate>Select by Merchant Store</SelectDate>
        <Select
          style={{ minWidth: 400 }}
          size="large"
          placeholder="All"
          onChange={selectLocation}
        >
          <Option value="all">All</Option>
          {[...merchants]
            .sort((a, b) =>
              a.merchantStoreName > b.merchantStoreName ? 1 : -1,
            )
            .map((m: Merchant) => (
              <Option key={m.merchantId} value={m.merchantId}>
                {m.merchantStoreName}
              </Option>
            ))}
        </Select>
      </RowMerchantSelect>
      <RowMerchantSelect>
        <SelectDate>Filter last4</SelectDate>
        <Last4
          value={filterValue}
          onChange={(event) => setFilterValue(event.target.value)}
        />
      </RowMerchantSelect>
      <RowLeft>
        <Statistic title="All trxns" value={filteredTransactions.length} />
        <Statistic
          title="Success trxns"
          valueStyle={{ color: '#3f8600' }}
          value={
            filteredTransactions.filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved',
            ).length
          }
        />
        <Statistic
          title="GBP"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 826, // GBP
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="EUR"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 978,
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="DKK"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 208,
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="NOK"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 578,
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="CZK"
          valueStyle={{ color: '#3f8600' }}
          value={filteredTransactions
            .filter(
              (t) =>
                t.transactionStatus === 'N' &&
                t.responseCode === '00' &&
                t.responseDescription === 'Approved' &&
                t.txnEffect === 'D' &&
                t.transactionCurrency === 203,
            )
            .reduce((a, t) => {
              if (t.transactionAmount !== t.billingAmount) {
                console.log('transactionId', t.txnGuid);
              }
              return a + t.transactionAmount;
            }, 0)}
        />
        <Statistic
          title="Failed trxns"
          valueStyle={{ color: '#cf1322' }}
          value={
            filteredTransactions.filter(
              (t) =>
                t.responseCode !== '00' ||
                t.transactionStatus !== 'N' ||
                t.responseDescription !== 'Approved',
            ).length
          }
        />
        <Statistic
          title="Card errors (05)"
          valueStyle={{ color: '#cf1322' }}
          value={
            filteredTransactions.filter((t) => t.responseCode === '05').length
          }
        />
        <Statistic
          title="Credit card rejections (58)"
          valueStyle={{ color: '#cf1322' }}
          value={
            filteredTransactions.filter((t) => t.responseCode === '58').length
          }
        />
      </RowLeft>
      <Table
        pagination={{ showSizeChanger: true }}
        columns={columns}
        dataSource={filteredTransactions.sort(
          (a, b) => moment(b.utcTime).unix() - moment(a.utcTime).unix(),
        )}
        size={'small'}
        loading={loading}
        rowKey={(record) => record.txnGuid}
      />
      <CSVRow>
        <CSVLink
          filename={'trxns_in_range.csv'}
          data={filteredTransactions.sort(
            (a, b) => moment(b.utcTime).unix() - moment(a.utcTime).unix(),
          )}
          separator={';'}
          enclosingCharacter={`'`}
          headers={[
            { label: 'Trxn id', key: 'txnGuid' },
            { label: 'UTC Date', key: 'utcTime' },
            { label: 'CardNo', key: 'cardNo' },
            { label: 'Card Source', key: 'cardSource' },
            { label: 'Amount', key: 'transactionAmount' },
            { label: 'Response code', key: 'responseCode' },
            { label: 'Response description', key: 'responseDescription' },
            { label: 'Transaction status', key: 'transactionStatus' },
            { label: 'Auth code', key: 'authCode' },
            { label: 'Terminal id', key: 'terminalCode' },
            { label: 'Merchant id', key: 'merchantCode' },
          ]}
        >
          <Button>Download trxns as CSV</Button>
        </CSVLink>
      </CSVRow>
      <Modal
        width={500}
        onCancel={() => setTransaction(null)}
        title="Transaction details"
        visible={transaction !== null}
        footer={null}
      >
        <Root>
          <Row>
            <p>TxnGuid:</p>
            <p>{transaction?.txnGuid}</p>
          </Row>
          <Row>
            <p>CardNo:</p>
            <p>{transaction?.cardNo}</p>
          </Row>
          <Row>
            <Tooltip title="Card type of txn (C: Credit, D: Debit, P: Prepaid)">
              <p>CardDci:</p>
            </Tooltip>
            <p>{transaction?.cardDci}</p>
          </Row>
          <Row>
            <Tooltip title="Card source of txn (V: Visa, M: Mastercard)">
              <p>CardSource:</p>
            </Tooltip>
            <p>{transaction?.cardSource}</p>
          </Row>
          <Row>
            <p>Request Date (TRT):</p>
            <p>{transaction?.requestDate.substr(0, 10)}</p>
          </Row>
          <Row>
            <p>Request Time (TRT):</p>
            <p>{transaction?.requestTime}</p>
          </Row>
          <Row>
            <p>Reponse Code:</p>
            <p>{transaction?.responseCode}</p>
          </Row>
          <Row>
            <p>Reponse Description:</p>
            <p>{transaction?.responseDescription}</p>
          </Row>
          <Row>
            <p>Trxn name:</p>
            <p>{transaction?.transactionName}</p>
          </Row>
          <Row>
            <p>Trxn amount:</p>
            <p>{transaction?.transactionAmount}</p>
          </Row>
          <Row>
            <p>Trxn currency:</p>
            <p>{transaction?.transactionCurrency}</p>
          </Row>
          <Row>
            <Tooltip title="Transaction status A: Advice, S: Suspicious, F: Reject, R: Reversed, V: Void, N: Normal, I: Invalid, P: Problem">
              <p>Trxn status:</p>
            </Tooltip>
            <p>{transaction?.transactionStatus}</p>
          </Row>
          <Row>
            <Tooltip title="Transaction effect(C:credit,D: debit)">
              <p>Trxn Effect:</p>
            </Tooltip>
            <p>{transaction?.txnEffect}</p>
          </Row>
          <Row>
            <Tooltip title="Entry type of transaction(C: Chip, T:Contactless, L:Manual, etc.. )">
              <p>Trxn Entry:</p>
            </Tooltip>
            <p>{transaction?.txnEntry}</p>
          </Row>
          <Row>
            <p>Trxn Region</p>
            <p>{transaction?.txnRegion}</p>
          </Row>
          <Row>
            <p>Terminal id:</p>
            <p>{transaction?.terminalCode}</p>
          </Row>
          <Row>
            <p>UTC time</p>
            <p>{transaction?.utcTime}</p>
          </Row>
        </Root>
      </Modal>
    </Root>
  );
};

/**
 * Exports
 */
export { SupportTransactionList };

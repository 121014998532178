/**
 * Imports
 */
import * as React from 'react';

import { Table, Button } from 'antd';
import styled from 'styled-components';
import { Merchant } from 'types';
import { getMerchants } from 'features/merchant/merchantAPI';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MerchantModal } from 'features/merchant';
import { merchantsSelector } from 'features/merchant/redux/selectors';
import { maybeUserSelector } from 'features/user/redux/selectors';
import { populateMerchants } from 'features/merchant/redux';
import moment from 'moment';
import { ROUTES } from 'router';

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const AddMerchantRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

const AddMerchantButton = styled(Button)`
  margin-right: 5px;
`;

const columns = [
  {
    title: 'Merchant id',
    dataIndex: 'merchantId',
    key: 'merchantId',
  },
  {
    title: 'EcommerceMerchantId',
    dataIndex: 'ecommerceMerchantId',
    key: 'ecommerceMerchantId',
  },
  {
    title: 'Merchant Legal Name',
    dataIndex: 'merchantName',
    key: 'merchantName',
  },
  {
    title: 'Merchant Store Name',
    dataIndex: 'merchantStoreName',
    key: 'merchantStoreName',
  },
  {
    title: 'Merchant country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Number of terminals',
    dataIndex: 'numberOfTerminals',
    key: 'numberOfTerminals',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (d: string) => moment(d).format('MMMM Do YYYY'),
  },
];
/**
 * Types
 */
type MerchantTableProps = object;

/**
 * LoginPage component
 */
const MerchantTable: React.FC<MerchantTableProps> = () => {
  const merchants = useSelector(merchantsSelector);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const user = useSelector(maybeUserSelector);
  const isPosSupport = user && user.userRoles.includes('pos-supporter');
  const history = useHistory();
  const closeModal = () => {
    setShowModal(false);
  };
  const _handleMerchantClick = (merchant: Merchant): void => {
    history.push(ROUTES.merchants.path(merchant.merchantId));
  };
  const dispatch = useDispatch();

  const fetchMerchants = async () => {
    const data = await getMerchants();
    dispatch(populateMerchants(data));
    setLoading(false);
  };
  const addMerchant = () => {
    console.log('add Merchant');
    setShowModal(true);
  };

  React.useEffect(() => {
    fetchMerchants();
    // eslint-disable-next-line
  }, []);

  return (
    <Root>
      {isPosSupport && (
        <AddMerchantRow>
          <AddMerchantButton type="primary" onClick={addMerchant}>
            Add Merchant
          </AddMerchantButton>
        </AddMerchantRow>
      )}
      <Table<Merchant>
        loading={loading}
        size={'small'}
        dataSource={merchants}
        columns={columns}
        pagination={{ showSizeChanger: true, defaultPageSize: 30 }}
        rowKey={(record) => record.merchantId}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => _handleMerchantClick(record),
          };
        }}
      />
      {showModal && <MerchantModal show={showModal} closeModal={closeModal} />}
    </Root>
  );
};

/**
 * Exports
 */
export { MerchantTable };

import React from 'react';
import logo from 'assets/logo/vibrantlogo.svg';

export type LogoProps = {
  width: number;
};

const Logo: React.FC<LogoProps> = (props) => {
  return <img width={props.width} src={logo} alt="Logo" />;
};
export { Logo };

/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';

import { logout, userUpdated, UserUpdatedAction } from './actions';

import { User } from '../types';

/**
 * Types
 */
export interface UserState {
  user: User | null;
}

/**
 * Constants
 */
const INITIAL_STATE: UserState = {
  user: null,
};

/**
 * `user` reducer
 */
const userReducer = createReducer(INITIAL_STATE, {
  [userUpdated.type]: (state, action: UserUpdatedAction) => {
    state.user = action.payload;
  },

  [logout.type]: (state) => {
    state.user = null;
  },
});

/**
 * Exports
 */
export default userReducer;

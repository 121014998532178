/**
 * Imports
 */
import * as React from 'react';
import { Button } from 'antd';
import { firebaseLogout } from 'firebaseAPI';
import { useHistory } from 'react-router-dom';

/**
 * Types
 */
export interface LogoutButtonProps {}

/**
 * LogoutButton component
 */
const LogoutButton: React.FC<LogoutButtonProps> = (props) => {
  /* History */
  const history = useHistory();

  /**
   *  Handle logout
   */
  const _handleLogout = async () => {
    await firebaseLogout();
    history.replace({ pathname: '/login' });
  };

  return (
    <Button type="default" onClick={_handleLogout}>
      Logout
    </Button>
  );
};

/**
 * Exports
 */
export { LogoutButton };

import { getFirebaseInstance } from './firebaseAdapter';
import { User } from 'features/user/types';
import { MERCHANT_API_URL } from 'config';
import axios from 'axios';
const firebaseLogin = async (
  email: string,
  password: string,
): Promise<User> => {
  const firebase = getFirebaseInstance();
  const signinResult = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  if (!signinResult.user) {
    throw new Error('Could not sign in user.');
  }

  return getUser(signinResult.user.uid);
};

const getIdToken = async (): Promise<string> => {
  const firebase = getFirebaseInstance();
  const token = await firebase.auth().currentUser!.getIdToken();
  return token;
};

const firebaseLogout = async (): Promise<void> => {
  const firebase = getFirebaseInstance();
  await firebase.auth().signOut();
};

const getUser = async (userId: string): Promise<User> => {
  const token = await getIdToken();
  const response = await axios.get(`${MERCHANT_API_URL}/api/me`, {
    headers: { token },
  });
  return response.data.result as User;
};

export { firebaseLogout, firebaseLogin, getIdToken, getUser };

/**
 * Imports
 */
import { Typography, Divider } from 'antd';
import * as React from 'react';

import styled from 'styled-components';
import { BackButton } from 'components';

const { Title } = Typography;

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * Types
 */
type TerminalDetailsProps = {
  terminalId: string;
  merchantId: string;
};

/**
 * MerchantDetails component
 */
const TerminalDetails: React.FC<TerminalDetailsProps> = ({ terminalId }) => {
  return (
    <Root>
      <BackButton />
      <Title level={3}>Terminal id: {terminalId}</Title>
      <Divider />
      <Title level={5}>Transactions</Title>
    </Root>
  );
};

/**
 * Exports
 */
export { TerminalDetails };

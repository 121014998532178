import { Terminal } from 'types';
import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { MERCHANT_API_URL } from 'config';

const TERMINAL_CREATE_URL = `${MERCHANT_API_URL}/api/v1/admin/terminals/create`;
const TERMINAL_UPDATE_URL = `${MERCHANT_API_URL}/api/v1/admin/terminals/update`;

export const getTerminals = async (merchantId: string): Promise<Terminal[]> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(
      `${MERCHANT_API_URL}/api/v1/merchants/${merchantId}/terminals`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Terminal[];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const createTerminal = async (
  merchantId: string,
  terminalId: string,
  addressLine1: string,
  city: string,
  zip: string,
  country: string,
  descriptor: string,
  type: string,
  serialNumber: string,
): Promise<string> => {
  const idToken = await getIdToken();
  const response = await axios.post(
    TERMINAL_CREATE_URL,
    {
      merchantId,
      terminalId,
      serialNumber,
      descriptor,
      addressLine1,
      country,
      city,
      zip,
      type,
    },
    {
      headers: { token: idToken },
    },
  );
  return response.data.result;
};
export const updateTerminal = async (
  merchantId: string,
  terminalId: string,
  addressLine1: string,
  city: string,
  zip: string,
  country: string,
  descriptor: string,
  type: string,
  serialNumber: string,
): Promise<string> => {
  const idToken = await getIdToken();
  const response = await axios.put(
    TERMINAL_UPDATE_URL,
    {
      merchantId,
      terminalId,
      serialNumber,
      descriptor,
      addressLine1,
      country,
      city,
      zip,
      type,
    },
    {
      headers: { token: idToken },
    },
  );
  return response.data.result;
};
export const upsertTerminal = async (
  merchantId: string,
  terminalId: string,
  addressLine1: string,
  city: string,
  zip: string,
  country: string,
  descriptor: string,
  type: string,
  serialNumber: string,
  upsertType: 'create' | 'update',
): Promise<string> => {
  if (upsertType === 'create') {
    return await createTerminal(
      merchantId,
      terminalId,
      addressLine1,
      city,
      zip,
      country,
      descriptor,
      type,
      serialNumber,
    );
  } else {
    return await updateTerminal(
      merchantId,
      terminalId,
      addressLine1,
      city,
      zip,
      country,
      descriptor,
      type,
      serialNumber,
    );
  }
};

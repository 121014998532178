import {
  Button,
  Typography,
  Divider,
  Select,
  DatePicker,
  Space,
  Input,
} from 'antd';

import { BackButton, Spinner } from 'components';
import { Merchant, Terminal, Transaction } from 'types';
import { getTerminals } from 'features/terminal/terminalAPI';

import { TransactionList } from './TransactionList';

/**
 * Imports
 */
import * as React from 'react';

import styled from 'styled-components';

import { getMerchantById } from 'features/merchant/merchantAPI';
import { getTransactions } from 'features/transaction/transactionAPI';
import moment, { Moment } from 'moment-timezone';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const QueryHeader = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 20px;
`;
const SelectTerminal = styled.div`
  display: flex;
  margin-right: 5px;
`;
const SelectDate = styled.div`
  display: flex;
  margin-right: 5px;
  margin-left: 15px;
`;
const FilterText = styled.div`
  margin-right: 5px;
`;
const SearchButtonHolder = styled.div`
  margin-left: 15px;
`;
const TableWrapper = styled.div`
  border-radius: 4px;
  /* th override */
  .ant-table-thead .ant-table-cell {
    background-color: white;
  }
  .ant-table-tbody .ant-table-cell {
    cursor: pointer;
  }
`;

/**
 * Types
 */
type TransactionMainProps = {
  id: string;
};

/**
 * MerchantDetails component
 */
const TransactionMain: React.FC<TransactionMainProps> = ({ id }) => {
  const [merchant, setMerchant] = React.useState<Merchant | null>(null);
  const [terminals, setTerminals] = React.useState<Terminal[]>([]);
  const [terminalId, setTerminalId] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');
  const [startDate, setStartDate] = React.useState<Moment>(
    moment().startOf('day'),
  );
  const [endDate, setEndDate] = React.useState<Moment>(moment().endOf('day'));
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchTerminals = async () => {
    const _terminals = await getTerminals(id);
    setTerminals(_terminals);
  };
  const fetchMerchant = async () => {
    const merchant = await getMerchantById(id);
    setMerchant(merchant);
  };
  const search = async () => {
    if (id && startDate && endDate) {
      setLoading(true);
      const ts = await getTransactions(id, startDate, endDate, terminalId);
      setLoading(false);
      setTransactions(ts);
    }
  };

  React.useEffect(() => {
    fetchTerminals();
    fetchMerchant();
    search();
    //eslint-disable-next-line
  }, []);

  if (!id || !merchant)
    return (
      <Root>
        <Spinner showSpinner={true} />
      </Root>
    );

  const select = (
    <>
      <SelectTerminal>Select Terminal</SelectTerminal>
      <Select
        defaultValue="all"
        style={{ width: 120 }}
        onChange={(id) => setTerminalId(id)}
      >
        <Option value="">all</Option>
        {terminals.map((t) => (
          <Option key={t.id} value={t.id}>
            {t.id}
          </Option>
        ))}
      </Select>
    </>
  );

  const onOk = (dates: any) => {
    const [startDate, endDate] = dates as Moment[];
    setStartDate(startDate.clone());
    setEndDate(endDate.clone());
  };

  const filteredTransactions = transactions
    .filter((t) => {
      const cardNo = t.cardNo ? t.cardNo : '';
      const last4 = cardNo.substr(cardNo.length - 4);
      return last4.includes(filterValue) || filterValue === '';
    })
    .sort((a, b) => moment(b.utcTime).unix() - moment(a.utcTime).unix());

  return (
    <Root>
      <BackButton />
      <Title level={3}>
        {merchant.merchantStoreName} ({merchant.country}) - Transactions
      </Title>
      <Divider />
      <QueryHeader>
        {select}
        <SelectDate>Select Date Range</SelectDate>
        <Space direction="vertical" size={12}>
          <RangePicker
            defaultValue={[startDate, endDate]}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={onOk}
            onChange={onOk}
          />
        </Space>
        <SearchButtonHolder>
          <Button onClick={search} type="primary">
            Search Transactions
          </Button>
        </SearchButtonHolder>
      </QueryHeader>
      {transactions.length > 0 && (
        <QueryHeader>
          <FilterText>
            Filter by last4
            <Input
              value={filterValue}
              onChange={(event) => setFilterValue(event.target.value)}
            />
          </FilterText>
        </QueryHeader>
      )}
      <Title level={5}>Transactions</Title>
      <TableWrapper>
        <TransactionList
          loading={loading}
          transactions={filteredTransactions}
        />
      </TableWrapper>
    </Root>
  );
};

/**
 * Exports
 */
export { TransactionMain };
